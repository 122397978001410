<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <form novalidate v-on:submit.prevent>
          <div class="row">
            <div class="col-sm-12">
              <h2>Forgot Password</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" :class="{'warn': $v.email.$error}">
                <!-- Label -->
                <label class="label-control">Email Address</label>
                <!-- Input -->
                <input v-model="$v.email.$model" class="form-control col-md-6" v-focus />
                <!-- Validation Messages -->
                <span v-if="$v.email.$dirty">
                  <small
                    class="form-text text-warning small"
                    v-if="!$v.email.required"
                  >An email address is required</small>
                  <small
                    class="form-text text-warning small"
                    v-if="!$v.email.email"
                  >An vaild email address is required</small>
                </span>
                <!-- Help Text -->
                <small class="form-text text-muted">Please enter your email address</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-primary" @click="submit" type="submit">Submit</button>
              <button class="btn btn-light ml-3" @click="cancel" type="button">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'forgotPassword',
  props: ['query'],
  data () {
    return {
      email: this.query.username
    }
  },
  methods: {
    async submit () {
      this.$v.email.$touch()

      if (!this.$v.$invalid) {
        var response = await axios.post('Account/ForgotPassword', { Email: this.email, ReturnUrl: this.query.returnUrl }, { showload: true })

        if (response.data.success) {
          await this.$snapbar.s('Password Reset Processed - You will receive an email if the account is found and the email address is verified')
          this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl } })
        } else {
          this.$toastr.e('Something went wrong', 'Error')
        }
      }
    },
    cancel () {
      this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl } })
    }
  },
  validations () {
    return {
      email: { required, email }
    }
  }
}
</script>
