var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "form",
          {
            attrs: { novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: { warn: _vm.$v.email.$error }
                  },
                  [
                    _c("label", { staticClass: "label-control" }, [
                      _vm._v("Email Address")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.email.$model,
                          expression: "$v.email.$model"
                        },
                        { name: "focus", rawName: "v-focus" }
                      ],
                      staticClass: "form-control col-md-6",
                      domProps: { value: _vm.$v.email.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.$v.email, "$model", $event.target.value)
                        }
                      }
                    }),
                    _vm.$v.email.$dirty
                      ? _c("span", [
                          !_vm.$v.email.required
                            ? _c(
                                "small",
                                { staticClass: "form-text text-warning small" },
                                [_vm._v("An email address is required")]
                              )
                            : _vm._e(),
                          !_vm.$v.email.email
                            ? _c(
                                "small",
                                { staticClass: "form-text text-warning small" },
                                [_vm._v("An vaild email address is required")]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("small", { staticClass: "form-text text-muted" }, [
                      _vm._v("Please enter your email address")
                    ])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit" },
                    on: { click: _vm.submit }
                  },
                  [_vm._v("Submit")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light ml-3",
                    attrs: { type: "button" },
                    on: { click: _vm.cancel }
                  },
                  [_vm._v("Cancel")]
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("h2", [_vm._v("Forgot Password")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }